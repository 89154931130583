<template>
  <section class="header">
    <img :src="bookInfo.book_cover" alt="book" style="width: 70px;">
    <div class="book-info">
      <div>
        <h3>{{bookInfo.book_name}}</h3>
        <span>{{bookInfo.book_author}}</span>
      </div>
      <div>
        <span>{{bookInfo.book_category_name}}</span>
        <span>{{bookInfo.finish_status === '1' ? '连载' : '完结'}}</span>
        <span>{{wordsFormat(bookInfo.book_words)}}字</span>
      </div>
      <p class="van-multi-ellipsis--l3 brief">{{bookInfo.book_summary}}</p>
    </div>
  </section>
  <section class="chapter">
    <h3>{{bookInfo.chapter_name}}</h3>
    <div v-html="formatBookContent"></div>
  </section>
  <section class="footer">
    <span @click="continueopen()">继续阅读 <i></i></span>
  </section>
</template>

<script>
import { getBookContent } from '../../api/book'
import { reportPvUv } from '../../api/active'
const SPACE = '&#12288;&#12288;'
export default {
  created() {
    getBookContent({
      book_id: this.book_id,
      chapter_id: this.chapter_id,
      link_id: this.promotion_id
    }).then(res => {
      this.bookInfo = res
      console.log(res);
    }),
    this.reportActivityPvUv()
  },
  data() {
    return {
      book_id: this.$route.params.bookId,
      chapter_id: this.$route.params.chapterId,
      promotion_id: this.$route.query.promotion_id,
      package_name:this.$route.query.package,
      bookInfo: {},
      url:'',
      continuetime:null,
      nopentime : Date.parse(new Date()) / 1e3,
      cnows:0
    }
  },
  computed: {
    formatBookContent() {
      let content = ''
      const chapter_content = this.bookInfo.chapter_content || ''
      chapter_content.trim()
        .split(/[\n]+[\s]+|[\n]+/)
        .forEach(item => content += `<p class="paragraph">${SPACE}${item}</p>`)
      return content
    }
  },
  methods: {
    toRead() {
      alert(123)
    },
    reportActivityPvUv() {
      let yy = new Date().getFullYear();
      let mm = (new Date().getMonth()+1).toString().padStart(2,'0')
      let dd = new Date().getDate().toString().padStart(2,'0')
      let hh = new Date().getHours().toString().padStart(2,'0')
      let mf = new Date().getMinutes().toString().padStart(2,'0')
      let ss = new Date().getSeconds().toString().padStart(2,'0')

      reportPvUv(
              {
                data:[{
                  "page_code":"LINK_DETAIL",
                  "action_type":"LINK",
                  'action_value':'LINK',
                  'action_time': yy+'-'+mm+'-'+dd+' '+hh+':'+mf+':'+ss,
                  content: {book_id:this.book_id,chapter_id:this.chapter_id},
                  'from_id': this.promotion_id
                }]
              },
              {
                'app-type': 'quickapp'
              }
      ).then(res => {
        console.log(res)
      })
    },
    wordsFormat(words = 0) {
      if (words < 100000) {
        return words
      }
      return (words / 10000).toFixed(1) + '万'
    },
    continueopen() {
      clearTimeout(this.continuetime)
      this.continuetime = setTimeout(() => {
        this.continueopen()
      }, 1000);
      this.cnows = Date.parse(new Date()) / 1e3 - this.nopentime;
      if (this.cnows > 5) {
        // 超过5s停止记时
        clearTimeout(this.continuetime);
        this.nopentime = Date.parse(new Date()) / 1e3
      }else{
        var hapLink = 'hap://app/'+this.package_name+'/pages/launch?book_id='+this.book_id+'&chapter_id='+this.book_id+'&promotion_id='+this.promotion_id
        var ohapa = document.createElement('a');
        ohapa.href = hapLink + '&pullMode=hapClick';
        ohapa.setAttribute('class', 'ahap');
        ohapa.setAttribute('style', 'position: absolute;left: -1000rem;height: 1rem;width: 1rem;overflow: hidden');
        document.body.appendChild(ohapa);
        ohapa.click();
      }
    }
  },
  // 启动后续引导展示

}
</script>

<style lang="less" scoped>
// 头部
.header {
  height: 128px;
  background: #F6F8F9;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  img {
    width: 69px;
    height: 96px;
    margin-right: 12px;
  }
}
.book-info {
  div:nth-child(1) {
    display: flex;
    justify-content: space-between;
    line-height: 15px;
    height: 15px;
    margin-bottom: 8px;
    h3 {
      font-size: 15px;
      font-weight: bold;
    }
    span {
      color: rgba(0, 0, 0, 0.4);
      font-size: 12px;
    }
  }
  div:nth-child(2) {
    line-height: 17px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.4);
    margin-bottom: 8px;
    span:nth-child(1), span:nth-child(2) {
      margin-right: 10px;
      position: relative;
    }
    span:nth-child(1):after, span:nth-child(2):after {
      content: '';
      display: block;
      height: 12px;
      width: 1px;
      background-color: rgba(0, 0, 0, 0.4);
      position: absolute;
      top: 2px;
      right: -5px;
    }

  }
  .brief {
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.56);
    height: 48px;
  }
}
// 章节
.chapter {
  height: calc(100vh - 128px);
  padding: 16px;
  background-color: #fff;
  overflow: hidden;
  box-sizing: border-box;
  h3 {
    color: #383845;
    line-height: 16px;
    font-size: 16px;
    margin-bottom: 12px;
    font-weight: bold;
  }
  div {
    font-size: 16px;
    color: #666;
    line-height: 24px;
    letter-spacing: 1px;
  }
}
.footer {
  height: 240px;
  width: 100%;
  background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  span {
    margin: 0 auto 20px;
    margin-bottom: 40px;
    display: flex;
    color: #FB4F4F;
    font-size: 28px;
    cursor: pointer;
  }
  i{
    background-image: url('../../assets/icon/arrow.png');
    width: 22px;
    height: 22px;
    background-size: cover;
    display: block;
    margin: 2px 0 0 5px;
  }
}
</style>
