import http from './config'

// 获取活动详情
export function getBookContent(params, headers) {
  return http({
    url: '/api/h5/spread/link',
    method: 'get',
    params,
    headers
  })
}